import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';
import Button from '../Button'; 
import Callout from '../Callout';
import { useNavigate } from 'react-router-dom';
import loadingGif from '../../assets/images/animation.gif';
import InputOverlay from '../InputOverlay'; 

function StudentsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'first_name', direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [editingPlan, setEditingPlan] = useState(null);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/baseline-plans/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        });
        setPlans(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching baseline plans:', error);
      }
    };

    fetchPlans();
  }, [API_ENDPOINT]);

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const toggleEditOverlay = (plan = null) => {
    setEditingPlan(plan);
  };

  const editForm = (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
  
        const headers = {
          Authorization: `Token ${localStorage.getItem('token')}`,
        };
  
        const updatedPlanData = {
          first_name: editingPlan && editingPlan.first_name ? editingPlan.first_name : '',
          last_name: editingPlan && editingPlan.last_name ? editingPlan.last_name : '',
        };
  
        try {
          await axios.put(
            `${API_ENDPOINT}/api/baseline-plans/${editingPlan.id}/`,
            updatedPlanData,
            { headers }
          );
  
          // Update the plan in state
          setPlans((prevPlans) =>
            prevPlans.map((plan) =>
              plan.id === editingPlan.id ? { ...plan, ...updatedPlanData } : plan
            )
          );
  
          toggleEditOverlay();
        } catch (error) {
          console.error('Error updating plan:', error);
        }
      }}
    >
      <div className="mb-3">
        <label htmlFor="editTitle" style={{ fontWeight: 'bold' }}>
          Plan Title
        </label>
        <input
          type="text"
          id="editTitle"
          className="form-control"
          value={editingPlan && editingPlan.first_name ? editingPlan.first_name : ''}
          onChange={(e) =>
            setEditingPlan((prev) => ({
              ...prev,
              first_name: e.target.value,
            }))
          }
          required
        />
      </div>
  
      <div className="mb-3">
        <label htmlFor="editDescription" style={{ fontWeight: 'bold' }}>
          Plan Description
        </label>
        <textarea
          id="editDescription"
          className="form-control"
          value={editingPlan && editingPlan.last_name ? editingPlan.last_name : ''}
          onChange={(e) =>
            setEditingPlan((prev) => ({
              ...prev,
              last_name: e.target.value,
            }))
          }
          rows="3"
          required
        />
      </div>
    
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit">Save Changes</Button>

      <Button
        variant="primary"
        onClick={async () => {
          const confirmDelete = window.confirm(
            'Are you sure you want to delete this plan? This action cannot be undone.'
          );

          if (confirmDelete) {
            try {
              const headers = {
                Authorization: `Token ${localStorage.getItem('token')}`,
              };

              await axios.delete(
                `${API_ENDPOINT}/api/baseline-plans/${editingPlan.id}/`,
                { headers }
              );

              setPlans((prevPlans) =>
                prevPlans.filter((plan) => plan.id !== editingPlan.id)
              );

              toggleEditOverlay();
            } catch (error) {
              console.error('Error deleting plan:', error);
            }
          }
        }}
      >
        Delete Plan
      </Button>
    </div>
    </form>
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`,
    };
    // const currentUserSchool = localStorage.getItem('cachedUser').school; 
    const planData = {
      username: `baseline_plan_${Date.now()}`,
      first_name: title, // Map title to `first_name`
      last_name: description, // Map description to `last_name`
      user_type: 4, // Ensure the user type is set to 4 (Baseline Plan)
    //   school: currentUserSchool, // Set to current user's school
    //   password: `${title}_baselineplan`, // Auto-generate password
    };

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/baseline-plans/`, planData, { headers });

      // Add the new plan and update the filtered list
      setPlans((prevPlans) => [...prevPlans, response.data]);
      setFilteredStudents((prevFiltered) => [...prevFiltered, response.data]);

      // Reset form fields and close the overlay
      setTitle('');
      setDescription('');
      setIsOverlayVisible(false);
    } catch (error) {
      console.error('Error creating baseline plan:', error);
    }
  };

  const planForm = (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-3">
        <label htmlFor="title" style={{ fontWeight: 'bold' }}>Plan Title</label>
        <input
          type="text"
          id="title"
          className="form-control"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="description" style={{ fontWeight: 'bold' }}>Plan Description</label>
        <textarea
          id="description"
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows="3"
          required
        />
      </div>

      <Button type="submit">Save Plan</Button>
    </form>
  );


  
  const handleViewAsStudent = async (studentId) => {
    try {
      const adminToken = localStorage.getItem('token');
      const response = await axios.post(`${API_ENDPOINT}/api/view-as-student/`, { studentId }, {
        headers: {
          'Authorization': `Token ${adminToken}`
        }
      });
      localStorage.setItem('adminToken', adminToken);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('isAdminViewingAsStudent', 'true');
      navigate('/plan');
      window.location.reload();
    } catch (error) {
      console.error("Error switching to student view:", error);
    }
  };

  const styles = {
    container: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // padding: '20px',
      marginBottom: '20px',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '20px',
    },
    h3: {
      marginTop: '0',
      marginBottom: '20px',
    },
    tableContainer: {
      width: '100%',
    },
    callout: {
      padding: '10px',
      marginBottom: '20px',
      backgroundColor: '#f8f9fa',
      borderLeft: '5px solid #007bff',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '20px',
      marginTop: '20px',
    },
    
  };

  return (
    <div className="page-container">
      <div >
        <h1>Baseline Plans</h1>
        <Callout text="Baseline Plans are educational plans that administrators can create and manage to provide students with pre-structured, goal-oriented pathways. These plans serve as automatically generated templates to help students quickly get started with a comprehensive educational plan, tailored to their educational and career goals." />

        <Button onClick={toggleOverlay} style={{ marginBottom: '20px' }}>Create Baseline Plan</Button>

        {isOverlayVisible && (
            <InputOverlay
            title="Create New Baseline Plan"
            description="Enter the details for the baseline plan below."
            onClose={toggleOverlay}
            content={planForm}
            />
        )}

        {editingPlan && (
            <InputOverlay
                title="Edit Baseline Plan"
                description="Update the details for the baseline plan below."
                onClose={() => toggleEditOverlay(null)}
                content={editForm}
            />
            )}

        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img
              src={loadingGif}
              alt="Loading..."
              style={{ width: '100px', height: '100px', marginBottom: '0px' }}
            />
          </div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <Table striped bordered hover>
            <colgroup>
                <col style={{ width: '30%' }} /> {/* Title column */}
                <col style={{ width: '50%' }} /> {/* Description column */}
                <col style={{ width: '20%' }} /> {/* Action column */}
            </colgroup>
              <thead>
                <tr>
                  <th>
                    Title
                  </th>
                  <th>
                    Description
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {plans.map((plan) => (
                  <tr key={plan.id}>
                    <td>{plan.first_name}</td>
                    <td>{plan.last_name}</td>
                    <td style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant="primary"
                        onClick={() => handleViewAsStudent(plan.id)}
                      >
                        View
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => toggleEditOverlay(plan)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentsList;