import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BaselinePlanTile from './BaselinePlanTile';
import loadingGif from '../../assets/images/animation.gif';
import { useNavigate } from 'react-router-dom';
import Callout from '../Callout';

const BaselinePlanSelection = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/baseline-plans-for-school/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        });

        // Add the "Create your plan from scratch" tile to the list of plans
        const defaultPlan = {
          id: 'create-from-scratch', // Unique ID for the default tile
          first_name: 'Create your plan from scratch',
          last_name: 'Start fresh and build a custom academic plan tailored to your goals.',
        };
        setPlans([defaultPlan, ...response.data]);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching baseline plans:', error);
      }
    };

    fetchPlans();
  }, [API_ENDPOINT]);

  const handlePlanSelection = async (planId) => {
    if (planId === 'create-from-scratch') {
      // Navigate directly to the next page without assigning courses
      navigate('/plan');
      return;
    }

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/assign-plan-to-student/`, { planId }, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      navigate('/plan');
      window.location.reload();
    } catch (error) {
      console.error('Error assigning plan:', error);
      alert('An error occurred while assigning the plan.');
    }
  };

  return (
    <div className="page-container">
      <h1>Select a Baseline Plan</h1>
      <Callout text="Choose from the available baseline plans below to get a jump start on your academic journey" />

      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px' }} />
        </div>
      ) : (
        <div style={styles.grid}>
          {plans.map((plan) => (
            <BaselinePlanTile key={plan.id} plan={plan} onSelect={handlePlanSelection} />
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '16px',
    marginTop: '20px',
  },
};

export default BaselinePlanSelection;