import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faPen } from '@fortawesome/free-solid-svg-icons';
import CourseCard2 from './CourseCard2';
import Button from '../Button'; // Assuming you have a reusable Button component
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const RequirementSection = ({ requirements, requirement, color, onEditRequirement, onEditCourseFulfillment, ownsPathway }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [courses, setCourses] = useState([]);
  const [autocompleteCourses, setAutocompleteCourses] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedNonDuplicables, setSelectedNonDuplicables] = useState([]);


    // Initialize state with requirement data
    const [requirementName, setRequirementName] = useState(requirement.name);
    const [requirementDescription, setRequirementDescription] = useState(requirement.description);
    const [requirementUnits, setRequirementUnits] = useState(requirement.number_of_units);
    const [requirementCourses, setRequirementCourses] = useState(requirement.number_of_courses);  
    
  const [hsFullAccessEnabled, setHsFullAccessEnabled] = useState(JSON.parse(localStorage.getItem('cachedUser')).school.hs_full_access_enabled);

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isOverlayVisible2, setIsOverlayVisible2] = useState(false);
  const [isOverlayVisible3, setIsOverlayVisible3] = useState(false);
  
  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

const toggleOverlay2 = () => {
  setIsOverlayVisible2(!isOverlayVisible2);
};

const toggleOverlay3 = () => {
  setIsOverlayVisible3(!isOverlayVisible3);
};

useEffect(() => {
  const token = localStorage.getItem('token'); 
  const headers = {
    Authorization: `Token ${token}`,
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; // Replace with your API endpoint
  const fetchCourses = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Token ${token}`,
      };
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; // Ensure this is set in your .env file
      const response = await axios.get(`${API_ENDPOINT}/api/courses/by-requirement/${requirement.uuid}`, { headers });
      setCourses(response.data); // Update state with fetched courses
    } catch (error) {
      console.error('Error fetching courses:', error);
      // Handle error, e.g., show an error message
    }
    setIsLoading(false);
  };
  const fetchAutocompleteOptions = async () => {
    // ... existing fetch logic
    const response = await axios.get(`${API_ENDPOINT}/api/courses/`, { headers });
    // setAutocompleteCourses(response.data); // Update state with fetched courses

    // Map the courses to fit the Autocomplete options structure
    const mappedOptions = response.data.map(course => ({
      label: `${course.course_id}: ${course.title}`,
      value: course.uuid,   // Assuming 'id' is a property of your course object
    }));
    setAutocompleteOptions(mappedOptions);
  };

  if (requirement.uuid) {
    fetchCourses();
    fetchAutocompleteOptions();
  }
  setIsLoading(false);
}, [requirement, color]); 


const handleSubmit = async (e) => {
  e.preventDefault();
  const updatedRequirementData = {
    name: requirementName,
    description: requirementDescription,
    number_of_units: requirementUnits,
    number_of_courses: requirementCourses
  };
  await onEditRequirement(requirement.uuid, updatedRequirementData);
  setIsOverlayVisible(false); // Close the overlay after submission
};

const handleSubmit2 = async (e) => {
  e.preventDefault(); // Prevent the default form submission behavior
  try {
    // Include the non_duplicables in the payload
    const payload = {
      requirement_uuid: requirement.uuid, // Or however you are currently setting this
      non_duplicables: selectedNonDuplicables,
    };

    const response = await onEditCourseFulfillment(requirement.uuid, selectedCourseId, payload); // Adjust this call as necessary
    if (selectedNonDuplicables.length > 0) {
      window.location.reload();
    } else {
      // Assuming the response includes the newly added course or an indication of success
      const newCourse = response.data;
      setCourses(prevCourses => [...prevCourses, newCourse]);
      
      setIsOverlayVisible2(false); // Close the overlay after submission
    }
    
  } catch (error) {
    console.error('Error adding course fulfillment:', error);
    // Handle error (e.g., show an error message)
  }
};



const handleDeleteRequirement = async (e) => {
  e.preventDefault(); // Prevent form submission if using a button of type "submit"

  const confirmDelete = window.confirm("Are you sure you want to delete this requirement?");
  if (!confirmDelete) {
    return; // User canceled the operation
  }

  try {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
    };
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    await axios.delete(`${API_ENDPOINT}/api/requirements/${requirement.uuid}/`, { headers });

    setIsOverlayVisible(false);
    window.location.reload();
  } catch (error) {
    console.error('Error deleting requirement:', error);
  }
};

const handleRemoveCourseFulfillment = async (e) => {
  e.preventDefault();
  
  if (!selectedCourseId) {
      alert("Please select a course to remove.");
      return;
  }

  const confirmRemove = window.confirm("Are you sure you want to remove this course fulfillment?");
  if (!confirmRemove) {
      return; // User canceled the operation
  }

  try {
      const token = localStorage.getItem('token');
      const headers = {
          Authorization: `Token ${token}`,
      };
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      // Adjust the URL as needed to match your API endpoint for removing a course fulfillment
      await axios.delete(`${API_ENDPOINT}/api/courses/${selectedCourseId}/requirement/`, {
          headers: headers,
          data: { requirement_uuid: requirement.uuid } // Sending the requirement UUID in the body of the DELETE request
      });

      // Update UI accordingly after successful removal
      setIsOverlayVisible3(false); // Close the overlay
      setCourses(courses.filter(course => course.uuid !== selectedCourseId)); // Remove the course from the local state
  } catch (error) {
      console.error('Error removing course fulfillment:', error);
      alert("Failed to remove course fulfillment.");
  }
};



  const requirementForm = (
    <form onSubmit={handleSubmit}>
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="requirementName" className="form-label">Requirement Name</label>
            <input type="text" className="form-control" id="requirementName" name="requirementName" value={requirementName} onChange={(e) => setRequirementName(e.target.value)} />
        </div>
  
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="requirementDescription" className="form-label">Requirement Description</label>
            <textarea className="form-control" id="requirementDescription" name="requirementDescription" rows="3" value={requirementDescription} onChange={(e) => setRequirementDescription(e.target.value)}></textarea>
      </div>
  
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="units" className="form-label">Number of Units Required</label>
            <input type="number" className="form-control" id="units" name="units" value={requirementUnits} onChange={(e) => setRequirementUnits(e.target.value)}/>
        </div>
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="courses" className="form-label">Number of Courses Required</label>
            <input type="number" className="form-control" id="courses" name="courses" value={requirementCourses} onChange={(e) => setRequirementCourses(e.target.value)}/>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button type="submit" className="btn btn-primary">Save Requirement</Button>
        <Button onClick={handleDeleteRequirement} className="btn btn-danger">Delete Requirement</Button>
        </div>

    </form>
  );

  const [showSecondAutocomplete, setShowSecondAutocomplete] = useState(false);
  const handleToggleChange = (event) => {
    setShowSecondAutocomplete(event.target.checked);
  };

  const courseFulfillmentForm = (
    <form onSubmit={handleSubmit2}>
      <div className="mb-3">
        <Autocomplete
          options={autocompleteOptions}
          onChange={(event, newValue) => {
            setSelectedCourseId(newValue ? newValue.value : null);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Course" variant="outlined" />
          )}
        />
      </div>
      <div className="mb-3">
        <FormControlLabel
          control={<Switch checked={showSecondAutocomplete} onChange={handleToggleChange} />}
          label="Are there any requirements that this course is non-duplicable towards?"
        />
      </div>
      {showSecondAutocomplete && (
        <div className="mb-3">
          <Autocomplete
            multiple // Add this if your Autocomplete supports multi-selection
            options={requirements
              .filter(req => req.uuid !== requirement.uuid) // Filter out the current requirement
              .map(req => ({ label: req.name, value: req.uuid }))
            }
            onChange={(event, newValue) => {
              setSelectedNonDuplicables(newValue.map(item => item.value)); // Store the selected non-duplicable requirement UUIDs
            }}
            renderInput={(params) => (
              <TextField {...params} label="Non-Duplicable Requirement" variant="outlined" />
            )}
          />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit" className="btn btn-primary">Add Course Fullfillment</Button>
      </div>
    </form>
  );


  const removeOptions = courses.map(course => ({
    label: `${course.course_id}: ${course.title}`,
    value: course.uuid,   // Assuming 'id' is a property of your course object
  }));
  
const removeCourseFulfillmentForm = (
    <form onSubmit={handleRemoveCourseFulfillment}>
      <div className="mb-3">
        <Autocomplete
          options={removeOptions}
          onChange={(event, newValue) => {
            setSelectedCourseId(newValue ? newValue.value : null);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Course" variant="outlined" />
          )}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit" className="btn btn-primary">Remove Course Fullfillment</Button>
      </div>
    </form>
  );  


  const toggleExpand = async () => {
    setIsExpanded(!isExpanded);
  };

  const styles = {
    container: {
      width: '100%', // Ensure the width matches the container
      backgroundColor: '#f5f5f5', // Light grey background
      borderRadius: '8px', // Rounded corners
      padding: '15px', // Padding inside the box
      marginBottom: '20px', // Space between each requirement section
    },
    icon: {
      fontSize: '24px',
      marginRight: '8px',
      cursor: 'pointer',
    },
    editIcon: {
      marginLeft: '8px',
      cursor: 'pointer',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    descriptionContainer: {
      marginTop: '10px',
      marginBottom: '10px'
    },
    title: {
      margin: 0,
      lineHeight: '24px', // Adjust as needed
    },
    descriptionText: {
      marginBottom: '5px', // Reduced bottom margin
    },
    boldText: {
      fontWeight: 'bold',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '20px', // Add space between buttons
      },

  };

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading message or a spinner
  }

  return (
    <div style={styles.container}>
      {isOverlayVisible && (
        <InputOverlay
            title="Add Requirement"
            description="Please edit the details of the requirement below."
            onClose={toggleOverlay}
            content={requirementForm}
        />
      )}
      {isOverlayVisible2 && (
      <InputOverlay
            title="Add Course Fullfillment"
            description="Search courses that fulfill this requirement"
            onClose={toggleOverlay2}
            content={courseFulfillmentForm}
        />
      )}
      {isOverlayVisible3 && (
      <InputOverlay
            title="Remove Course Fullfillment"
            description="Search courses that you no longer want to fulfill this requirement"
            onClose={toggleOverlay3}
            content={removeCourseFulfillmentForm}
        />
      )}
      <div style={styles.headerContainer} onClick={toggleExpand}>
        <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} style={styles.icon} />
        <h3 style={styles.title}>{requirement.name}</h3>
      </div>
      {isExpanded && (
        <div >
          <div style={styles.descriptionContainer}>
            <p style={styles.descriptionText}>{requirement.description}</p>
            <p style={styles.descriptionText}>
            <strong>Courses: {requirement.number_of_courses}</strong> 
            </p>
            <p style={styles.descriptionText}>
          <strong>Units: {requirement.number_of_units}</strong>
          </p>
          </div>
          {(hsFullAccessEnabled || ownsPathway) && (
            <div style={styles.buttonsContainer}>
            <Button onClick={toggleOverlay}>Edit Requirement</Button>
            <Button onClick={toggleOverlay2}>Add Course Fullfillment</Button>
            <Button onClick={toggleOverlay3}>Remove Course Fullfillment</Button>
            </div>
          )}
          <h3>Courses</h3>
          
          <div>
            {courses.length > 0 ? (
              courses.map(course => (
                <CourseCard2 key={course.course_id} course={course} />
              ))
            ) : (
              <p>No course fulfillment</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RequirementSection;
