import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import "../styles/SignUp.css";
import "../styles/SignUpPage.css";

function CCCApplySuccess() {
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        const school = JSON.parse(localStorage.getItem("cachedUser")).school;
            
        if (school.baseline_plans_enabled) {
            navigate("/baseline-plans");
        } else {
            navigate("/plan");
        }
    };

    return (
        <div className="signup-page">
            <Container className="signup-container">
                <h2 className="center-text">Application Submitted</h2>
                <p className="center-text">
                    🎉 Your CCCApply application has been successfully submitted!
                </p>
                <p className="center-text">
                    Your next step is to start building your academic plan!
                </p>

                <div className="center-text">
                    <Button 
                        variant="primary" 
                        className="btn-block custom-button" 
                        onClick={handleGoToDashboard}
                    >
                        Next
                    </Button>
                </div>
            </Container>
        </div>
    );
}

export default CCCApplySuccess;