import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";
import "../styles/SignUp.css";  
import "../styles/SignUpPage.css";  

function StudentInfo() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();

    const school = JSON.parse(localStorage.getItem("cachedUser")).school;
            console.log(school.ccc_apply_enabled);

    const [form, setForm] = useState({        
        grade: "",
        dob: "",
        address: "",
        zipCode: "",
        guardianFirstName: "",
        guardianLastName: "",
        guardianEmail: "",
        guardianPhone: "",
    });

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
    
        setForm((prevForm) => ({
            ...prevForm,
            [name]: type === "checkbox" ? checked : value,  // Convert checkbox values to true/false
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Convert DOB from "YYYY-MM-DD" → "MMDDYYYY"
        const formatDob = (dob) => {
            if (!dob) return "";
            const [year, month, day] = dob.split("-");
            return `${month}${day}${year}`;
        };
    
        const formattedDob = formatDob(form.dob);
    
        // Prepare the final form data
        const formData = { ...form, dob: formattedDob };
    
        try {
            await axios.post(`${API_ENDPOINT}/api/student-info/`, formData, {
                headers: { Authorization: `Token ${localStorage.getItem("token")}` }
            });

            const school = JSON.parse(localStorage.getItem("cachedUser")).school;
            console.log(school.ccc_apply_enabled);
            
            if (school.ccc_apply_enabled) {
                navigate("/student-demographics");
            } else if (school.baseline_plans_enabled) {
                navigate("/baseline-plans");
            } else {
                navigate("/plan");
            }
    
        } catch (error) {
            console.error("Error submitting student info:", error);
            alert("An error occurred. Please try again.");
        }
    };
    return (
        <div className="signup-page">
            <Container className="signup-container">
                <h2 className="center-text">Student Information</h2>
                
                <Form onSubmit={handleSubmit}>
                    {/* School Year Dropdown */}
                    <Form.Group className="mb-3" controlId="formGrade">
                        <Form.Label className="bold-label">Current Grade</Form.Label>
                        <Form.Select 
                            name="grade" 
                            value={form.grade} 
                            onChange={handleChange} 
                            required
                        >
                            <option value="">Select School Year</option>
                            <option value="8">8th Grade</option>
                            <option value="9">9th Grade</option>
                            <option value="10">10th Grade</option>
                            <option value="11">11th Grade</option>
                            <option value="12">12th Grade</option>
                            <option value="college">College Student</option>
                            <option value="other">Other</option>
                        </Form.Select>
                    </Form.Group>

                    {/* Date of Birth */}
                    <Form.Group className="mb-3" controlId="formDob">
                        <Form.Label className="bold-label">Date of Birth</Form.Label>
                        <Form.Control 
                            type="date" 
                            name="dob" 
                            value={form.dob} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>

                    {/* Mailing Address */}
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Label className="bold-label">Mailing Address</Form.Label>
                        <Form.Control type="text" name="address" value={form.address} onChange={handleChange} required />
                    </Form.Group>

                    {/* Zip Code (Fixed Name) */}
                    <Form.Group className="mb-3" controlId="zipCode">
                        <Form.Label className="bold-label">Zip Code</Form.Label>
                        <Form.Control type="text" name="zipCode" value={form.zipCode} onChange={handleChange} required />
                    </Form.Group>

                    {/* Parent/Guardian Info (Fixed Field Names) */}
                    <Form.Group className="mb-3" controlId="formGuardianFirstName">
                        <Form.Label className="bold-label">Parent/Guardian First Name</Form.Label>
                        <Form.Control type="text" name="guardianFirstName" value={form.guardianFirstName} onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGuardianLastName">
                        <Form.Label className="bold-label">Parent/Guardian Last Name</Form.Label>
                        <Form.Control type="text" name="guardianLastName" value={form.guardianLastName} onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGuardianPhone">
                        <Form.Label className="bold-label">Parent/Guardian Phone Number</Form.Label>
                        <Form.Control type="text" name="guardianPhone" value={form.guardianPhone} onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGuardianEmail">
                        <Form.Label className="bold-label">Parent/Guardian Email</Form.Label>
                        <Form.Control type="email" name="guardianEmail" value={form.guardianEmail} onChange={handleChange} required />
                    </Form.Group>                   

                    <div className="center-text">
                        <Button variant="primary" type="submit" className="btn-block custom-button">
                            Continue
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
}

export default StudentInfo;