import React from "react";
import { Container, Card, ListGroup } from "react-bootstrap";

function CCCApplyAnswers() {
    return (
        <Container className="mt-5">
            <h2 className="text-center mb-4">Your CCCApply Application Answers</h2>
            <p className="text-center">
                Below are the answers EdXcelerate is submitting on your behalf for your CCCApply application.
            </p>

            {/* Term Applying For */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Term Applying For</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Selected:</strong> Winter 2025 Quarter (January 06, 2025 to March 28, 2025)
                    </ListGroup.Item>
                    {/* <ListGroup.Item>Spring 2025 Quarter (April 01, 2025 to June 26, 2025)</ListGroup.Item>
                    <ListGroup.Item>Fall 2025 Quarter (September 15, 2025 to December 11, 2025)</ListGroup.Item> */}
                </ListGroup>
            </Card>

            {/* Educational Goal */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Educational Goal</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Selected:</strong> Undecided on goal
                    </ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Major Category */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Major Category</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Selected:</strong> All Majors
                    </ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Intended Major or Program of Study */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Intended Major or Program of Study</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Selected:</strong> Non-Credit (all non-credit programs)
                    </ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Previous Legal Name */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Previous Legal Name</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No</ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Social Security Number */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Social Security Number</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Selected:</strong> No, I do not have a social security number or taxpayer identification number, or I decline to provide one at this time.
                    </ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Parent/Guardian Information */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Parent/Guardian Information</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> I have a parent or guardian.</ListGroup.Item>
                    <ListGroup.Item><strong>First Name:</strong> [Guardian First Name Provided]</ListGroup.Item>
                    <ListGroup.Item><strong>Last Name:</strong> [Guardian Last Name Provided]</ListGroup.Item>
                    <ListGroup.Item><strong>Relationship:</strong> Guardian</ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Foster Care Status (Only If Applicable) */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Foster Care Status</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Foster Care Status Provided]</ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Current Mailing Address</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> My mailing address is the same as the Permanent Address in my OpenCCC Account.</ListGroup.Item>
                </ListGroup>
            </Card>

            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Current Mailing Address</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> My mailing address is the same as the Permanent Address in my OpenCCC Account.</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">College Enrollment Status: As of January 05, 2025, I will have the following college enrollment status: </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Enrolling in high school (or lower grade) and college at the same time</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Current or Most Recent High-School Attended </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> I attended high school.</ListGroup.Item>
                    <ListGroup.Item><strong>Entered:</strong> [High School connected to your user]</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">High School Transcript Information
College staff use this information to provide guidance. Your responses will not affect your admission to college.

What was the highest grade you completed in high school?
</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Currently enrolled in 9th grade or lower grade, or no high school at all</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Current Mailing Address</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> My mailing address is the same as the Permanent Address in my OpenCCC Account.</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">College Education
                College education level as of January 05, 2025 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No degree</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Colleges/Universities Attended
Specify the number of colleges you have attended including those you are currently attending.
</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> None</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">Citizenship & Immigration
                Citizenship & Immigration Status </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Citizenship & Immigration Status provided]</ListGroup.Item>
                    <ListGroup.Item><strong>If not U.S. citizen: Selected:</strong> Other and No documents</ListGroup.Item>
                </ListGroup>
            </Card>
            {/* Current Mailing Address */}
            <Card className="mb-4">
                <Card.Header className="fw-bold">U.S. Military/Dependent of Military
                U.S. Military status as of January 05, 2025 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> None apply to me</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">California Residence: Has your parent or guardian lived in California continuously since January 05, 2023?                </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Yes [If California zip code provided]</ListGroup.Item>
                    <ListGroup.Item><strong>Selected:</strong> No [If California zip code not provided]</ListGroup.Item>
                    <ListGroup.Item><strong>Selected:</strong>  Check this box if you have not yet arrived in California, or if you do not plan to relocate to California.
                    [If California zip code not provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are you homeless now or have you been homeless at any time during the past 24 months? </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Homelessness status provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Have you ever been in court-ordered foster care? </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Foster care status provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are you comfortable reading and writing English?</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Yes</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are you interested in receiving information about money for college?</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are you receiving TANF/CalWORKs, SSI, or General Assistance? </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are you interested in participating in a sport while attending college? 

(Your response does not obligate you in any way. To be eligible to participate on an intercollegiate team, you must be enrolled in at least 12 units.)

</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No, I am not interested in participating in a sport (beyond taking P.E. classes).
                    </ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Per U.S. Department of Education guidelines, colleges are required to collect this data.

Check the box below if you identify as Hispanic or Latino. If you indicate that you are Hispanic or Latino, you will have the option to select more specific ancestry groups.
Are you Hispanic or Latino?

</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Yes [If Hispanic or Latino provided]</ListGroup.Item>
                    <ListGroup.Item><strong>Selected:</strong> No [If Hispanic or Latino not provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Check all of the ethnicity, nation, and ancestry groups that you identify with. When you select a major ethnicity group, you will have the option to select more specific ancestry groups. Select all that apply.
                </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Selected Race / Ethnicities provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Parent/Guardian Educational Levels

Regardless of your age, please indicate the education levels of the parents and/or guardians who raised you. 
Parent or Guardian 1  </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Unknown</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Parent or Guardian 2  </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> Unknown</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Dependents
Do you have children or dependents who receive more than half of their support from you

</Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Gender
                Gender in this context, means a person's sex, or a person's gender identity and gender expression.  </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Gender provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Emergency contact - This information will only be used in the event of a personal or campus emergency.
Who would you like us to contact in an emergency?
 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Name of guardian provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Please provide the person's phone number (including area code)

 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> [Phone number of guardian provided]</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are you enrolling at LTCC for one of these special programs only (click below to view the list)?


 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">Are all of the classes you plan to take at LTCC solely for fun and/or personal enrichment?


 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> No</ListGroup.Item>
                </ListGroup>
            </Card>
            <Card className="mb-4">
                <Card.Header className="fw-bold">What was the primary way you heard about Lake Tahoe Community College?



 </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>Selected:</strong> High school counselor</ListGroup.Item>
                </ListGroup>
            </Card>
            <p className="text-center mt-4">
                If any of these answers need to be changed, please contact EdXcelerate support before your application is submitted.
            </p>
        </Container>
    );
}

export default CCCApplyAnswers;