import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";
import "../styles/SignUp.css";  
import "../styles/SignUpPage.css";  

function StudentDemographics() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();

    const school = JSON.parse(localStorage.getItem("cachedUser")).school;

    const [form, setForm] = useState({        
        citizenshipStatus: "",
        fosterCare: "",
        fosterCareAge: "",
        homelessness: "",
        gender: "",
        raceEthnicity: [],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,  // ✅ Keep values as strings
        }));
    };

    const handleRaceChange = (e) => {
        const { value, checked } = e.target;
        setForm((prevForm) => {
            const updatedRaceEthnicity = checked
                ? [...prevForm.raceEthnicity, value] // Add if checked
                : prevForm.raceEthnicity.filter(race => race !== value); // Remove if unchecked
            return { ...prevForm, raceEthnicity: updatedRaceEthnicity };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert DOB from "YYYY-MM-DD" → "MMDDYYYY"
        const formatDob = (dob) => {
            if (!dob) return "";
            const [year, month, day] = dob.split("-");
            return `${month}${day}${year}`;
        };
    
        const formattedDob = formatDob(form.dob);
    
        // Prepare the final form data
        const formData = { ...form, dob: formattedDob };
    
        try {
            await axios.post(`${API_ENDPOINT}/api/student-demographics/`, formData, {
                headers: { Authorization: `Token ${localStorage.getItem("token")}` }
            });

            if (school.ccc_apply_enabled) {
                navigate("/cccapply/consent");
            } else if (school.baseline_plans_enabled) {
                navigate("/baseline-plans");
            } else {
                navigate("/plan");
            }
    
        } catch (error) {
            console.error("Error submitting student demographics:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="signup-page">
            <Container className="signup-container">
                <h2 className="center-text">Student Demographics</h2>
                
                <Form onSubmit={handleSubmit}>
                    
                    {/* Citizenship & Immigration Status */}
                    <Form.Group className="mb-3" controlId="formCitizenship">
                        <Form.Label className="bold-label">Citizenship & Immigration Status</Form.Label>
                        <Form.Select 
                            name="citizenshipStatus" 
                            value={form.citizenshipStatus} 
                            onChange={handleChange} 
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="US Citizen">U.S. Citizen</option>
                            <option value="Non US Citizen">Not a US Citizen</option>
                        </Form.Select>
                    </Form.Group>

                    {/* Foster Care History */}
                    <Form.Group className="mb-3">
                        <Form.Label className="bold-label">Have you ever been in court-ordered foster care?</Form.Label>
                        <Form.Select name="fosterCare" value={form.fosterCare} onChange={handleChange} required>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </Form.Select>
                    </Form.Group>

                    {form.fosterCare === "yes" && (
                        <Form.Group className="mb-3">
                            <Form.Label className="bold-label">If yes, select one:</Form.Label>
                            <Form.Select name="fosterCareAge" value={form.fosterCareAge} onChange={handleChange} required>
                                <option value="">Select</option>
                                <option value="current">I am currently in foster care (including extended foster care after age 18).</option>
                                <option value="after13">I exited the foster care system on or after my 13th birthday.</option>
                                <option value="before13">I exited the foster care system before my 13th birthday.</option>
                                <option value="unknown">I am not sure at what age I exited foster care.</option>
                            </Form.Select>
                        </Form.Group>
                    )}

                    {/* Homelessness Status */}
                    <Form.Group className="mb-3">
                        <Form.Label className="bold-label">Are you homeless now or have you been homeless at any time during the past 24 months?</Form.Label>
                        <Form.Select name="homelessness" value={form.homelessness} onChange={handleChange} required>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </Form.Select>
                    </Form.Group>

                    {/* Gender */}
                    <Form.Group className="mb-3">
                        <Form.Label className="bold-label">Gender</Form.Label>
                        <Form.Select name="gender" value={form.gender} onChange={handleChange} required>
                            <option value="">Select</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                            <option value="non-binary">Non-binary</option>
                            <option value="decline">Decline to state</option>
                        </Form.Select>
                    </Form.Group>

                    {/* Race/Ethnicity */}
                    <Form.Group className="mb-3">
                        <Form.Label className="bold-label">Race/Ethnicity</Form.Label>
                        <div className="flex-container">
                            {[
                                "Hispanic or Latino",
                                "Asian",
                                "American Indian or Alaska Native",
                                "Black or African American",
                                "Native Hawaiian or Other Pacific Islander",
                                "Middle Eastern or North African",
                                "White"
                            ].map((race) => (
                                <Form.Check
                                    key={race}
                                    type="checkbox"
                                    label={race}
                                    value={race}
                                    checked={form.raceEthnicity.includes(race)}
                                    onChange={handleRaceChange}
                                    className="me-3"
                                />
                            ))}
                        </div>
                    </Form.Group>

                    {/* Submit Button */}
                    <div className="center-text">
                        <Button variant="primary" type="submit" className="btn-block custom-button">
                            Continue
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
}

export default StudentDemographics;