import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Callout from '../Callout'; // Assuming you've already created this component
import Button from '../Button'; // Assuming you have a reusable Button component
import CourseCard from './CourseCard2';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faUpload } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import loadingGif from '../../assets/images/animation.gif';


const CoursesTab = () => {

  const tagStyle = {
    padding: '0px 10px',
    borderRadius: '5px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000017', 
    margin: '5px',
    cursor: 'pointer',
  };

  const xStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
    fontWeight: 'bold'
  };

  const styles = {
    tooltipIcon: {
      display: 'inline-block',
      marginLeft: '8px',
      cursor: 'pointer',
      position: 'relative',
    },
    tooltipText: {
      visibility: 'hidden',
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      borderRadius: '4px',
      padding: '5px',
      position: 'absolute',
      zIndex: 1,
      bottom: '125%', // Adjust position as needed
      left: '50%',
      transform: 'translateX(-50%)',
      width: '200px',
      fontSize: '0.9em',
      lineHeight: '1.4',
      opacity: 0, // Hidden by default
      transition: 'visibility 0s, opacity 0.3s ease-in-out',
    },
    tooltipIconHover: {
      visibility: 'visible',
      opacity: 0.9,
    },
    buttonsContainer: {
      display: 'flex',
      gap: '20px', // Add space between buttons
      },
  };



  const token = localStorage.getItem('token'); 
  const headers = {Authorization: `Token ${token}`,};
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; // Replace with your API endpoint
  const [isLoading, setIsLoading] = useState(true); 
  const [isAddCourseVisible, setIsAddCourseVisible] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [courses, setCourses] = useState([]); // State for storing courses
  const [pathways, setPathways] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [selectedRequirementIds, setSelectedRequirementIds] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const tags = JSON.parse(localStorage.getItem('cachedUser')).school.tags;
  const tagOptions = tags ? Object.entries(tags).map(([key, value]) => ({ key, value })) : [];
  const [prerequisiteGroups, setPrerequisiteGroups] = useState([[]]); // Initial state with one empty group
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isUploadOverlayVisible, setIsUploadOverlayVisible] = useState(false);
  const handleAddGroup = (event) => {
    event.preventDefault(); // Prevents form submission
    setPrerequisiteGroups([...prerequisiteGroups, []]); // Adds a new empty group
  };
  const handleCourseSelection = (index, newValue) => {
    const updatedGroups = [...prerequisiteGroups];
    updatedGroups[index] = newValue.map(course => course.uuid); // Store course UUIDs
    setPrerequisiteGroups(updatedGroups);
  };


  useEffect(() => {
    fetchCoursesAndPathways();
  }, []);

  useEffect(() => {
      doSearch();
  }, [selectedRequirementIds]);
  

  const fetchCoursesAndPathways = async () => {
    try {
      const coursesResponse = await axios.get(`${API_ENDPOINT}/api/courses/`, { headers });
      const pathwaysResponse = await axios.get(`${API_ENDPOINT}/api/pathways/`, { headers });
      setPathways(pathwaysResponse.data);
      setCourses(coursesResponse.data);
      setIsLoading(false); 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleAddCourse = () => {
    if (isAddCourseVisible) {
      // Reset all form-related state when closing the form
      setSelectedTags([]);
      setPrerequisiteGroups([[]]);
    }
    setIsAddCourseVisible(!isAddCourseVisible);
  };
  
  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleAddCourse = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let courseData = Object.fromEntries(formData.entries());
    courseData.prerequisite_groups = prerequisiteGroups.map(group => group);

        
    // Add tags if selected
    courseData.tags = selectedTags.reduce((acc, tag) => {
      acc[tag.key] = tag.value; // Use both key and value
      return acc;
    }, {}); // Convert selectedTags array to a dictionary of key-value pairs
    
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      };
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    
      const response = await axios.post(`${API_ENDPOINT}/api/courses/create/`, JSON.stringify(courseData), { headers });
    
      // Add the new course to the state
      setCourses((prevCourses) => [...prevCourses, response.data]);
    
      // Close the form
      setIsAddCourseVisible(false);
    } catch (error) {
      if (error.response) {
        const errorMessage = JSON.stringify(error.response.data, null, 2);
        alert(`Error adding course: ${errorMessage}`);
      }
    }
  };

    
    
    // Function to handle search
  const doSearch = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (searchTerm) queryParams.append('search', searchTerm);
      if (selectedRequirementIds) queryParams.append('requirement', selectedRequirementIds);
      const response = await axios.get(`${API_ENDPOINT}/api/courses/?${queryParams.toString()}`, { headers });
      setCourses(response.data); // Update the courses state with the filtered list
      setSearchPerformed(true); // Mark that a search has been performed
       // Mark that a search has been performed
    } catch (error) {
      console.error('Error fetching filtered courses:', error);
      // Handle error, e.g., show an error message
    }
  };
      

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      doSearch(); // Call the search function when Enter key is pressed
    }
  };
  
  const findRequirementLabel = (requirementId) => {
    for (const pathway of pathways) {
      for (const requirement of pathway.requirements) {
        if (requirement.uuid === requirementId) {
          return `${pathway.name}: ${requirement.name}`; // Format as needed
        }
      }
    }
    return null;
  };

  const clearFilter = () => {
    setSelectedRequirementIds(null);
    
  };

  const handleFilter = async (e) => {
    // e.prevsentDefault();
    setIsOverlayVisible(false); // Close the overlay after submission
    doSearch();
  };

  const options = pathways.flatMap(pathway => 
    pathway.requirements.map(requirement => ({
      label: `${pathway.name}: ${requirement.name}`,
      value: requirement.uuid,
    }))
  );

  
  const FilterForm = (
    <form onSubmit={handleFilter}>
      <div className="mb-3">
        <Autocomplete
          options={options}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedRequirementIds(newValue.value);
              setIsOverlayVisible(false); // Assuming you want to close the overlay here
            } else {
              setSelectedRequirementIds(null);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Requirement" variant="outlined" />
          )}
        />
      </div>
    </form>
  );

  const addCourseForm = (
    <form onSubmit={handleAddCourse}>
      {/* Course ID */}
      <div className="mb-3">
        <label htmlFor="course_id" className="form-label">Course ID</label>
        <input type="text" className="form-control" id="course_id" name="course_id" />
      </div>
  
      {/* Title */}
      <div className="mb-3">
        <label htmlFor="title" className="form-label">Title</label>
        <input type="text" className="form-control" id="title" name="title" />
      </div>
  
      {/* Description */}
      <div className="mb-3">
        <label htmlFor="description" className="form-label">Description</label>
        <textarea className="form-control" id="description" name="description" rows="3"></textarea>
      </div>
  
  
      {/* Units */}
      <div className="mb-3">
        <label htmlFor="units" className="form-label">Units</label>
        <input type="number" className="form-control" step="0.01" id="units" name="units" />
      </div>
  
      {/* Prerequisites */}
      <div className="mb-3">
        <label htmlFor="prerequisite" className="form-label">
          Prerequisites
          <span 
            style={styles.tooltipIcon}
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
          >
            ⓘ
            <span style={{
              ...styles.tooltipText,
              ...(isTooltipVisible ? styles.tooltipIconHover : {})
            }}>
              Each prerequisite group is a set of courses where students need to complete at least one course from each group.
            </span>
          </span>
        </label>
        {prerequisiteGroups.map((group, index) => (
          <Autocomplete
            key={index}
            multiple
            id={`prerequisites-${index}`}
            options={courses}
            getOptionLabel={(option) => `${option.course_id}: ${option.title}`}
            onChange={(event, newValue) => handleCourseSelection(index, newValue)}
            renderInput={(params) => (
              <TextField {...params} label={`Prerequisite Group ${index + 1}`} variant="outlined" />
            )}
            style={{ marginBottom: '15px' }}
          />
        ))}
        <Button onClick={(event) => handleAddGroup(event)} style={{ marginTop: '10px' }}>+</Button>
      </div>

      {/* Tags */}

      <div className="mb-3">
        <label htmlFor="tags" className="form-label">Tags</label>
        <Autocomplete
          multiple
          id="tags-autocomplete"
          options={tagOptions} // Array of objects with key and value
          getOptionLabel={(option) => `${option.key}`} // Display key and value
          onChange={(event, newValue) => {
            setSelectedTags(newValue); // Store the selected tag objects
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tags" variant="outlined" placeholder="Select tags" />
          )}
          value={selectedTags}
        />
      </div>

  
      {/* Submit Button */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit" className="btn btn-primary">Add Course</Button>
      </div>
    </form>
  );

  const validateCSVData = (data) => {
    const errors = [];
  
    data.forEach((row, index) => {
      // Validate prerequisites
      try {
        const prerequisites = JSON.parse(row.Prerequisites);
        if (!Array.isArray(prerequisites)) {
          errors.push(`Row ${index + 1}: Prerequisites must be a JSON array.`);
        }
      } catch {
        errors.push(`Row ${index + 1}: Invalid prerequisites format.`);
      }
  
      // Validate tags
      try {
        const tags = JSON.parse(row.Tags);
        if (!Array.isArray(tags)) {
          errors.push(`Row ${index + 1}: Tags must be a JSON array.`);
        }
      } catch {
        errors.push(`Row ${index + 1}: Invalid tags format.`);
      }
    });
  
    return errors;
  };

  const uploadOverlayContent = (
    <div>
      <input
        type="file"
        accept=".csv"
        onChange={(e) => handleFileChange(e.target.files[0])}
        style={{ marginTop: '10px' }}
      />
    </div>
  );

  const uploadOverlayDescription = (
    <div>
      <p>
        Follow these instructions to format your data correctly before uploading:
      </p>
      <ul>
        <li>
          <strong>Course ID</strong>: A unique identifier for each course (e.g., <code>CS101</code>).
        </li>
        <li>
          <strong>Title</strong>: The name of the course (e.g., <code>Introduction to Computer Science</code>).
        </li>
        <li>
          <strong>Description</strong>: A brief summary of the course content. Wrap the text in double quotes if it includes commas.
        </li>
        <li>
          <strong>Units</strong>: The number of credit hours or units for the course.
        </li>
        <li>
        <strong>Prerequisites</strong>:
        <ul>
          <li>
            Specify groups of courses required before taking this course.
          </li>
          <li>
            Format: <code>[["CourseID1","CourseID2"],["CourseID3"]]</code>
          </li>
          <li>
            Example: <code>[["CS100","CS105"],["MATH101"]]</code>
            <ul>
              <li>Students must complete <strong>either CS100 or CS105</strong> (Group 1).</li>
              <li>Students must also complete <strong>MATH101</strong> (Group 2).</li>
            </ul>
          </li>
          <li>
            <strong>Important:</strong> Ensure that all `Course IDs` in the prerequisites already exist in the system or are also being uploaded.
          </li>
          <li>
            Leave empty (<code>[]</code>) if there are no prerequisites.
          </li>
        </ul>
      </li>
        <li>
          <strong>Tags</strong>: Attributes or keywords describing the course.
          <ul>
            <li>Format: <code>["Tag1", "Tag2"]</code></li>
            <li>Example: <code>["Grade Bump", "In Person at High School"]</code></li>
          </ul>
        </li>
      </ul>
      <p>
        When you're ready, download the <a href="/template_course_upload.csv" download="template_course_upload.csv" target="_blank" rel="noopener noreferrer">template CSV</a>, fill it out, and upload it here.
      </p>
    </div>
  );

  const handleFileChange = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/upload-courses-csv/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Courses uploaded successfully!');
      fetchCoursesAndPathways(); // Refresh courses
      setIsUploadOverlayVisible(false); // Close the overlay
    } catch (error) {
      if (error.response && error.response.data.errors) {
        // Display specific row errors
        console.error('Upload errors:', error.response.data.errors);
        alert('Some courses could not be uploaded. Check the console for details.');
      } else {
        console.error('Error uploading courses:', error);
        alert('Failed to upload courses. Please check the file format.');
      }
    }
  };
  
  
  return (
    
      <div className="page-container">
      <h1>My Courses</h1>
      <div style={styles.buttonsContainer}>
        <Button onClick={toggleAddCourse}>Add Course</Button>
        <Button onClick={() => setIsUploadOverlayVisible(true)}>
          Upload Courses
        </Button>
      </div>
    
      <Callout text="Below is a list of all the courses your students will be able to see. This includes courses taught by your school and courses transferable to your school. This will help your students understand what is available to them and how to build their 4 year plan." />
      
      <div style={{ marginBottom: '20px' }}>
      {/* Search bar container */}
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginBottom: '10px' }}>
      <input
        type="text"
        placeholder="Search Courses"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown} 
        style={{
          flexGrow: 1,
          padding: '5px',
          paddingLeft: '30px',
          marginRight: '8px', // Space between input and button
          // Ensure the input height matches the button, adjust as needed
          height: '40px', // Example height, adjust based on your button's height
          boxSizing: 'border-box', // Ensures padding is included in the height calculation
        }}
      />
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#ccc',
          pointerEvents: 'none',
        }}
      />
      {/* Button might need specific height to align perfectly with the input */}
      <Button variant="secondary" onClick={doSearch} style={{ height: '40px', boxSizing: 'border-box' }}>Search</Button>

    </div>


  {/* Filter icon container, now aligned left */}
    <div style={{ display: 'flex', alignSelf: 'flex-start'}}> {/* Align items to the center vertically and left horizontally */}
      <Button variant="secondary" icon={faFilter} onClick={toggleOverlay}>Filter by Requirement</Button>
    </div>
  </div>

  {isUploadOverlayVisible && (
      <InputOverlay
        title="Upload Courses CSV"
        description={uploadOverlayDescription}
        onClose={() => setIsUploadOverlayVisible(false)}
        content={uploadOverlayContent}
      />
    )}

  {isOverlayVisible && (
        <InputOverlay
            title="Filter Courses"
            description="Filter by requirement fulfillment."
            onClose={toggleOverlay}
            content={FilterForm}
        />
      )}

    {selectedRequirementIds && (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: '10px 0' }}>
        <div style={tagStyle}>
          {findRequirementLabel(selectedRequirementIds)}
          <span onClick={clearFilter} style={xStyle}>×</span>
        </div>
      </div>
    )}




      {isAddCourseVisible && (
        <InputOverlay
            title="Add New Course"
            description="Enter the details for the new course below."
            onClose={toggleAddCourse}
            content={addCourseForm}
        />
      )}
      
      {isLoading ? (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
              </div>
          ) : (

      <div style={{ marginTop: '20px' }}>
        {/* Placeholder for course cards */}
        <div>
        {courses.map(course => (
          <CourseCard key={course.UUID} course={course} />
        ))}
      </div>
      </div>
    )}
    </div>
  );
}

export default CoursesTab;