import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";

function CCCApplyConfirmation() {
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state && location.state.email ? location.state.email : "";
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [confirmationCode, setConfirmationCode] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // ✅ Send confirmation code to backend
            await axios.post(`${API_ENDPOINT}/api/cccapply/confirm/`, { confirmation_code: confirmationCode }, {
                headers: { Authorization: `Token ${localStorage.getItem("token")}` }
            });
    
            // ✅ Redirect to success page
            navigate("/cccapply/success");
    
        } catch (error) {
            console.error("Error submitting confirmation code:", error);
            alert("Invalid confirmation code. Please try again.");
        }
    };

    return (
        <div className="signup-page">
            <Container className="signup-container">
                <h2 className="center-text">Verify Email</h2>
                <p className="center-text">A security code has been sent to your email. Please enter it below.</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formConfirmationCode">
                        <Form.Label className="bold-label">Confirmation Code</Form.Label>
                        <Form.Control type="text" name="confirmationCode" value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} required />
                    </Form.Group>

                    <div className="center-text">
                        <Button variant="primary" type="submit" className="btn-block custom-button">
                            Submit Code
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
}

export default CCCApplyConfirmation;