import React from 'react';
import Button from '../Button';

const BaselinePlanTile = ({ plan, onSelect }) => {
  return (
    <div style={styles.tile}>
      <h3 style={styles.title}>{plan.first_name}</h3>
      <p style={styles.description}>{plan.last_name}</p>
      <Button onClick={() => onSelect(plan.id)}>Select Plan</Button>
    </div>
  );
};

const styles = {
  tile: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    margin: '16px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  description: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '12px',
  },
};

export default BaselinePlanTile;