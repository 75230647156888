import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/SignUp.css';
import '../styles/SignUpPage.css';  // Import the CSS for this component



function SignUp() {

    const renderTooltip = (passwordValid) => (
        <Tooltip 
            id="password-tooltip"
            style={{
                background: "transparent", // ✅ Makes tooltip background transparent
                border: "none", // ✅ Removes the border around the tooltip
                padding: "0", // ✅ Removes extra padding
                margin: "0", // ✅ Ensures it fits tightly around content
                boxShadow: "none" // ✅ Eliminates default Bootstrap tooltip shadow
            }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                borderRadius: "5px",
                overflow: "hidden", // ✅ Ensures no extra spacing
                padding: "0",
            }}>
                <p style={{ 
                    ...styles.passwordRequirement, 
                    ...(passwordValid.length ? styles.valid : styles.invalid) 
                }}>
                    At least 8 characters
                </p>
                <p style={{ 
                    ...styles.passwordRequirement, 
                    ...(passwordValid.uppercase ? styles.valid : styles.invalid) 
                }}>
                    At least one uppercase letter
                </p>
                <p style={{ 
                    ...styles.passwordRequirement, 
                    ...(passwordValid.lowercase ? styles.valid : styles.invalid) 
                }}>
                    At least one lowercase letter
                </p>
                <p style={{ 
                    ...styles.passwordRequirement, 
                    ...(passwordValid.number ? styles.valid : styles.invalid) 
                }}>
                    At least one number
                </p>
                <p style={{ 
                    ...styles.passwordRequirement, 
                    ...(passwordValid.specialChar ? styles.valid : styles.invalid) 
                }}>
                    At least one special character (!, @, #, $, %, ^, & or *)
                </p>
            </div>
        </Tooltip>
    );

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [form, setForm] = useState({
        signupCode: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    // const [passwordFocused, setPasswordFocused] = useState(false);
    const [passwordValid, setPasswordValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false
    });

    const navigate = useNavigate();

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "password") {
            validatePassword(e.target.value);
        }
    };

    const validatePassword = (password) => {
        const specialChars = /[!@#$%^&*]/;
        setPasswordValid({
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /\d/.test(password),
            specialChar: specialChars.test(password)
        });
    };

    const allValid = Object.values(passwordValid).every(Boolean);


    const handleSubmit = async e => {
        e.preventDefault();
        if (form.password !== form.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
        if (!allValid) {
            alert("Your password does not meet the requirements.");
            return;
        }
        try {
            const response = await axios.post(`${API_ENDPOINT}/api/register/`, {
                signup_code: form.signupCode,
                email: form.email,
                password: form.password,
                first_name: form.firstName,
                last_name: form.lastName,
            });
    
            const userType = response.data.user_type;
    
            const loginResponse = await axios.post(`${API_ENDPOINT}/api/login/`, {
                email: form.email,
                password: form.password,
            });
    
            localStorage.setItem('token', loginResponse.data.token);
    
            if (userType === 1) { // If student, go to Student Info Page
                navigate('/student-info');
            } else {
                navigate('/');
            }
            // ✅ Force page reload to register logged-in state
            setTimeout(() => {
            window.location.reload();
        }, 100); // Small delay to allow navigation
        } catch (error) {
            console.error(error);
            alert('An error occurred. Please try again later.');
        }
    };


    const styles = {
        passwordRequirement: {
            fontSize: "12px",
            padding: "5px 10px",  // ✅ Reduced padding for a compact design
            margin: "0",  // ✅ Removed margin to remove space between rows
            borderBottom: "1px solid #ddd", // ✅ Subtle separator for each row
            transition: "background-color 0.3s ease-in-out",
        },
        valid: {
            backgroundColor: "darkgreen", // ✅ Turns green when valid
        },
        invalid: {
            // backgroundColor: "lightcoral", // ❌ Turns red when invalid
        }
    };


    return (
        <div className="signup-page">
        <Container className="signup-container">
            <h2 className="center-text">Sign Up</h2>
            <p className="center-text">Already registered? <Link to="/log-in">Sign In</Link></p>
            
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label className="bold-label">First Name</Form.Label>
                    <Form.Control type="text" name="firstName" value={form.firstName} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label className="bold-label">Last Name</Form.Label>
                    <Form.Control type="text" name="lastName" value={form.lastName} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label className="bold-label">Email</Form.Label>
                    <Form.Control type="email" name="email" value={form.email} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label className="bold-label">Password</Form.Label>
                    <OverlayTrigger placement="right" overlay={renderTooltip(passwordValid)}>
                        <Form.Control 
                            type="password" 
                            name="password" 
                            value={form.password} 
                            onChange={handleChange}
                        />
                    </OverlayTrigger>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label className="bold-label">Confirm Password</Form.Label>
                    <Form.Control 
                        type="password" 
                        name="confirmPassword" 
                        value={form.confirmPassword} 
                        onChange={handleChange} 
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formSignupCode">
                    <Form.Label className="bold-label">Sign-Up Code</Form.Label>
                    <Form.Control
                        type="text"
                        name="signupCode"
                        value={form.signupCode}
                        onChange={handleChange}
                    />
                </Form.Group>

                <div className="center-text">
                    <Button 
                        variant="primary" 
                        type="submit" 
                        className="btn-block custom-button"
                    >
                        Sign Up
                    </Button>
                </div>
            </Form>
        </Container>
        </div>
    );
}

export default SignUp;