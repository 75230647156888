import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";

function CCCApplyConsent() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem("cachedUser"));

    const [consents, setConsents] = useState({
        consentDataRelease: false,
        consentCCCApply: false,
        consentVerification: false,
        consentPrivacyPolicy: false,
        consentSubmitApplication: false,
        consentFinancialAid: false,
        consentTermsOfUse: false,
        consentEdXcelerate: false,
        electronicSignature: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConsents((prevConsents) => ({
            ...prevConsents,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!consents.electronicSignature) {
            alert("You must type your full name as an electronic signature.");
            return;
        }

        try {
            // Redirect user to confirmation page before making the API requests
            navigate("/cccapply/confirmation");

            await axios.post(`${API_ENDPOINT}/api/cccapply/consent/`, consents, {
                headers: { Authorization: `Token ${localStorage.getItem("token")}` },
            });

            await axios.post(`${API_ENDPOINT}/api/cccapply/start/`, {}, {
                headers: { Authorization: `Token ${localStorage.getItem("token")}` },
            });

        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div>
            <Container 
                className="container-lg p-4 overflow-auto" 
                style={{ 
                    maxWidth: "1000px", // Keeps the form from getting too wide
                    maxHeight: "80vh", // Prevents the form from stretching too far
                    border: "1px solid #ddd", // Adds a subtle border for better visual separation
                    borderRadius: "8px", // Rounds the corners slightly
                    backgroundColor: "#f8f9fa", // Light background for a cleaner look
                    padding: "20px", // Consistent padding
                }}
            >
                <h2 className="center-text">Consent For Submission of CCCApply Application</h2>
                <p className="mb-4 text-start">
                    You are about to submit your application to Lake Tahoe Community College.
                    NO CHANGES can be made to your application once it is submitted.

                    California state law* allows you to submit your application and residency information online with an electronic signature verification. Your completion of this page will provide the necessary verification for electronic submission. The security and privacy of the information in your submitted application are protected as described in the CCCApply Privacy Policy.
                </p>
                <p className="mt-2 text-start" >* Section 54300 of subchapter 4.5 of chapter 5 of division 6 of title 5 of the California Code of Regulations.</p>
                <Form onSubmit={handleSubmit}>

                    {/* Legal Consent: EdXcelerate Authorization */}
                    <Form.Group className="mb-4 text-start">
                        <Form.Check
                            type="checkbox"
                            name="consentEdXcelerate"
                            checked={consents.consentEdXcelerate}
                            label={
                                <>
                                    By checking here, I, <strong>{consents.electronicSignature || user.first_name + " " + user.last_name}</strong> authorize EdXcelerate to submit my OpenCCC application on my behalf and understand that EdXcelerate is not responsible for any issues arising from my application submission.
                                    I acknowledge that EdXcelerate will answer the questions in this application as outlined in our process. You can review the exact responses provided on the application <a href="/cccapply/application" target="_blank" rel="noopener noreferrer">here</a>.
                                </>
                            }
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    {/* Legal Consent: Release of Information */}
                    <Form.Group className="mb-4 text-start">
                    <Form.Check
                        type="checkbox"
                        name="consentPrivacyPolicy"
                        checked={consents.consentPrivacyPolicy}
                        label={
                            <>
                                By checking here, I, <strong>{consents.electronicSignature || user.first_name + " " + user.last_name}</strong> authorize the Chancellor's Office, California Community Colleges, and the community colleges I am attending to release necessary personal information contained in my education records, including my Social Security number, for the purposes described below. To learn more about how your personal information is stored and used, see the{" "}
                                <a href="https://portal.cccmypath.org/uPortal/p/privacy-policy.ctf1/max/render.uP" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>.
                            </>
                        }
                        onChange={handleChange}
                        required
                    />
                    {/* Nested Consent Details */}
                    <ul 
                        className="mt-2" 
                        style={{ 
                            paddingLeft: "30px", // Moves the list inward for better readability
                            marginBottom: "10px", // Adds spacing below the list
                        }}
                    >
                        <li>To federal or state agencies to evaluate jointly administered programs or to comply with reporting requirements.</li>
                        <li>To data matching services to measure student success in transferring to four-year colleges or universities.</li>
                        <li>To colleges, universities, or government agencies to promote outreach to students and to enhance transfer.</li>
                        <li>To the California Student Aid Commission to facilitate the award of financial aid.</li>
                        <li>To organizations or agencies assisting the Chancellor's Office or the community colleges you attend with research and analysis.</li>
                    </ul>
                </Form.Group>

                    {/* Legal Consent: Submit Application */}
                    <Form.Group className="mb-4 text-start">
                        <Form.Check
                            type="checkbox"
                            name="consentSubmitApplication"
                            checked={consents.consentSubmitApplication}
                            label={
                                <>
                                    By checking here, I, <strong>{consents.electronicSignature || user.first_name + " " + user.last_name}</strong>, declare that:
                                </>
                                
                            }
                            onChange={handleChange}
                            required
                        />
                        {/* Nested Consent Details */}
                    <ul 
                        className="mt-2" 
                        style={{ 
                            paddingLeft: "30px", // Moves the list inward for better readability
                            marginBottom: "10px", // Adds spacing below the list
                        }}
                    >
                        <li>all the information in this application pertains to me.</li>
                        <li>Under penalty of perjury, the statements and information submitted in this online admission application are true and correct.</li>
                        <li>I understand that falsification, withholding pertinent data, or failure to report changes in residency may result in District action.</li>
                        <li>I understand that all materials and information submitted by me for purposes of admission become the property of Lake Tahoe Community College.</li>
                    </ul>
                    </Form.Group>

                    {/* Legal Consent: Financial Aid */}
                    <Form.Group className="mb-4 text-start">
                        <Form.Check
                            type="checkbox"
                            name="consentFinancialAid"
                            checked={consents.consentFinancialAid}
                            label={
                                <>
                                By checking here, I, <strong>{consents.electronicSignature || user.first_name + " " + user.last_name}</strong>, acknowledge understanding that:
                                </>
                            }
                            onChange={handleChange}
                            required
                        />
                        <ul 
                        className="mt-2" 
                        style={{ 
                            paddingLeft: "30px", // Moves the list inward for better readability
                            marginBottom: "10px", // Adds spacing below the list
                        }}
                    >
                        <li>Federal and state financial aid programs are available and may include aid in the form of grants, work study, and/or any available student loans. I am aware that I may apply for assistance for up to the total cost of my education including enrollment fees, books & supplies, transportation, and room and board expense.</li>
                        <li>I may apply for financial assistance if I am enrolled in an eligible program of study (certificate, associate degree, or transfer), and may receive aid if qualified, regardless of whether I am enrolled full-time or part-time.</li>
                        <li>Financial aid program information and application assistance are available in the financial aid office at the college. The application is also available on-line.</li>
                    </ul>
                    <p>Note: CCCApply will provide links to financial aid information and applications after you submit this application. You can also find financial aid information at www.icanaffordcollege.com, and on most college websites.</p>
                    </Form.Group>

                    {/* Legal Consent: Terms of Use */}
                    <Form.Group className="mb-4 text-start">
                        <Form.Check
                            type="checkbox"
                            name="consentTermsOfUse"
                            checked={consents.consentTermsOfUse}
                            label={
                                <>
                                    I agree to the{" "}
                                    <a href="https://www.cccmypath.org/uPortal/p/terms-use.ctf1/max/render.uP" target="_blank" rel="noopener noreferrer">
                                        Terms of Use
                                    </a>{" "}
                                    and to receive emails from my designated schools and programs.
                                </>
                            }
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    

                    {/* Electronic Signature */}
                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold mb-2">
                            By signing below, I agree to the use of my electronic signature, which has the same legal effect as a handwritten signature.
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="electronicSignature"
                            value={consents.electronicSignature}
                            onChange={handleChange}
                            placeholder="Type your full name"
                            required
                        />
                    </Form.Group>

                    <div className="center-text">
                        <Button variant="primary" type="submit" className="btn-block custom-button">
                            Continue
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
}

export default CCCApplyConsent;